import { Routes } from '@angular/router';
import { LegacyRenderComponent } from '../../pages/legacy-render/legacy-render.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { Role } from 'src/app/core/enums/role';

export const ADMIN_LEGACY_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'management',
    pathMatch: 'full'
  },
  {
    path: 'management', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Administração',
      legacyRoute: 'administracao',
      roles: ['Administrador Contratanet'],
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'unlock',

    },
  },
  {
    path: 'companies',
    component: LegacyRenderComponent,
    data: {
      name: 'Empresas',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'portal/empresas',
      notInHeaderMenu: true,
    },
  },
  {
    path: 'change-password', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Alterar senha',
      legacyRoute: 'comuns/trocasenhausuario.aspx',
      roles: ['Administrador Contratanet'],
      permissions: {
        only: 'PasswordChange',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 1
    },
  },
  {
    path: 'validation',
    component: LegacyRenderComponent,
    data: {
      name: 'Validação',
      legacyRoute: 'operacional/validacao',
      roles: ['Administrador Contratanet'],
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    },
  },
  {
    path: 'yduqs-integration',
    component: LegacyRenderComponent,
    data: {
      name: 'Integração YDUQS',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'admin/integracao',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    },
  },
  {
    path: 'sine',
    component: LegacyRenderComponent,
    data: {
      name: 'Sine',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'operacional/sine',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    }
  },
  {
    path: 'utility',
    component: LegacyRenderComponent,
    data: {
      name: 'Utilitários',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'admin/utilitarios',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    }
  },
  {
    path: 'jobs',
    component: LegacyRenderComponent,
    data: {
      name: 'Cadastrar Vagas',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'vaga/cadastrar.aspx',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    }
  },
  {
    path: 'import',
    component: LegacyRenderComponent,
    data: {
      name: 'Importação',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'admin/importacao',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    }
  },
  {
    path: 'services',
    component: LegacyRenderComponent,
    data: {
      name: 'Serviços',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'administracao/servicos',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    }
  },
  {
    path: 'support',
    component: LegacyRenderComponent,
    data: {
      name: 'Suporte',
      roles: ['Administrador Contratanet'],
      legacyRoute: 'administracao/atendimento',
      notInHeaderMenu: true,
      permissions: {
        only: 'ContratanetAdminAccess',
        redirectTo: '/unauthorized'
      },
    }
  },
];
