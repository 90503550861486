import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './core/analytics/google-analytics.service';
import { AcessedPageLogService } from './core/services/acessed-page-log.service';
import { AuthService } from './core/services/auth.service';
import { LanguageService } from './core/services/language.service';
import { MainPageService } from './core/services/main-page.service';
import { LoaderService } from './core/services/shared/loader.service';
import { StartupService } from './core/services/startup.service';
import { ToastAdapter } from './core/services/toast-adapter.service';
import { fadeInOutAnimation } from './shared/animations/fade-in-out.animation';
import { UrlAuthRedirectService } from './shared/pages/url-auth-redirect/url-auth-redirect.service';
import { CreateDocumentOnNewStructureService } from './core/services/create-document-on-new-structure.service';
import { IframeRenderedService } from './shared/pages/iframe-error/iframe-rendered.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOutAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  routerEvents: Subscription;
  hideHeader: boolean = true;
  hideFooter: boolean;
  loading: boolean;
  isMobile = false;
  isTestEnviroment =  !['prd', 'prd-uae'].includes(environment.env) && !this.iframeRenderedService.isAppRenderedinsideIframe()
  enviroment = environment.env
  showHeaderWhenLogin = true;
  loadHeader = false;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _loadService: LoaderService,
    private _authService: AuthService,
    private _urlAuthRedirectService: UrlAuthRedirectService,
    private _startupService: StartupService,
    private _mainpageService: MainPageService,
    private location: Location,
    private googleAnalyticsService: GoogleAnalyticsService,
    private createDocumentOnNewStructureService: CreateDocumentOnNewStructureService,
    private toasterAdpter: ToastAdapter,
    private acessedPageLogService: AcessedPageLogService,
    private iframeRenderedService: IframeRenderedService

  ) {
    _loadService.isLoading.pipe(startWith(false), delay(0)).subscribe(res => this.loading = res);
    this._authService.initLogin.subscribe(init => {
      this.hideHeader = init;
    });

  }

  ngOnInit() {

    if (this._authService.isLoggedIn()) {
      this.hideHeader = false;
    }


    this.resolveResize(window.innerWidth);


    this.routerEvents = this._router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError ||
            event instanceof ActivationStart
        ),
      )
      .subscribe(event => {
        if (event instanceof ActivationStart) {
          this.hideFooter = event.snapshot.data.hideFooter;
          this.hideHeader = event.snapshot.data.hideHeader;
          this._cdr.detectChanges();
        }

        if (event instanceof NavigationStart) {
          this._loadService.show();
          return;
        }
        if (event instanceof NavigationEnd) {
          this.acessedPageLogService.logPage();
          let params = new HttpParams();
          Object.keys(this._activatedRoute.snapshot.queryParams).forEach(param => {
            // remove params credential and token from all routes
            if (param !== 'credential' && 'token') {
              params = params.append(param, this._activatedRoute.snapshot.queryParams[param]);
            }
          });
          this.location.replaceState(location.pathname, params.toString());
          const isRouteAuth = event.url.includes('auth') && !event.url.includes('companies');
          if (event.url === '/') {
            const isPortalCnetHome = this._startupService.isPortalContratanet();
            this.hideHeader = isPortalCnetHome;
            this.hideFooter = isPortalCnetHome;
          }
          this.hideFooter = this.hideFooter || isRouteAuth;
          this._cdr.detectChanges();
          this.loadHeader = true;
          this.googleAnalyticsService.addPagePathToAnalytics(event.urlAfterRedirects);
          this._loadService.forceHide();
        }
      });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }


  checkAndLogoutLegacy() {
    if (localStorage.getItem('userToken')) {
      this._authService.logout();
    }
  }


  ngOnDestroy() {
    this.routerEvents?.unsubscribe();
  }

  resolveResize(innerWidth) {
    if (innerWidth <= 992) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resolveResize(event.target.innerWidth);
  }

  @HostListener('window:message', ['$event'])
  public onMessage(event: any) {
    if (event.origin !== window.location.origin) {

      switch (event.data.value) {
        case 'header':
          let r = encodeURIComponent(event.data.src);
          if (r.includes('contratanet')) {
            window.open(window.location.pathname + '?src=' + r, '_blank').focus();
          }
          else {
            window.open(event.data.src, '_blank').focus();
          }
          break;
        case 'samePage':
          window.location.href = event.data.src;
          break;
        case 'redirectSpa':
          if (event.data.returnUrl) {
            this._router.navigate([event.data.returnUrl]);
          }
          break;
        case 'auth':
          let returnUrl = event.data.returnUrl ? event.data.returnUrl : null;
          this._authService.logoutAndRedirectToLogin(returnUrl);
          break;
        case 'iframeUrl':
          this._urlAuthRedirectService.currentIframeUrl = !event.data.src.includes('function-iframe') ? event.data.src : '';

          this.checkAndLogoutLegacy();
          break;
        case 'loadHome':
          this._router.navigate(['']);
          break;
        case 'showToaster':
          if (event.data) { this.toasterAdpter.open(event.data.message, event.data.time ? event.data.time : 5000, event.data.type); };
          break;
        case 'createDocumentOnNewStructure':
          this.createDocumentOnNewStructureService.openOnNewTab(event.data.data.contractDocumentCode, event.data.data.isElegibleToBePrinted, event.data.data.type, event.data.data.language, event.data.data.saveDocument);
          break;
      }
    } else {
      switch (event.data.value) {
        case 'navigateHome':
          const urlToRedirect = this._mainpageService.getReturnUrl();
          this._router.navigate([urlToRedirect]);
          break;
      }
    }
  }

  @HostListener('window:storage', ['$event'])
  public storgeEvent(event) {
    if (event.storageArea != localStorage) return;
    if (event.key === 'loggedOut') {
      if (localStorage.getItem('loggedOut')) {
        this._authService.RedirectToLogin();
        localStorage.removeItem('loggedOut');
      }
    }
  }
}
