<div class="test-enviroment-indicator" *ngIf="isTestEnviroment">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <cnet-icon name="exclamation-triangle" [color]="'warning'" size="3"></cnet-icon>
    <p class="m-0"> AMBIENTE: {{enviroment}} </p>
  </div>
</div>
<app-header-toolbar *ngIf="!hideHeader && loadHeader"></app-header-toolbar>

<main class="main-container" [class.main-container-test-enviroment]="isTestEnviroment">

  <div class="main-container-loader" [class.main-container-loader-test-enviroment]="isTestEnviroment" *ngIf="loading"
    [@fadeInOutAnimation]>
    <cnet-progress-spinner color="secondary" diameter="80" strokeWidth="6"></cnet-progress-spinner>
  </div>
  <router-outlet #o="outlet"></router-outlet>
</main>

<app-footer *ngIf="!hideFooter && !isMobile" style="bottom: 25px;" appIframeRendered></app-footer>
<app-config-tester *ngIf="isTestEnviroment" ></app-config-tester>
