import { PortalColors } from './portal-colors.model';
import { SocialMedia } from './socialMedia.model';

export type PortalType = 'Instituicao' | 'Empresa' | 'Padrao';

export enum enumPortalType {
  Instituicao = 'Instituicao',
  Empresa = 'Empresa',
  Padrao = 'Padrao'
}

export class Portal {
  uniqueId: string;
  name: string;
  darkLogoUrl: string;
  lightLogoUrl: string;
  colors: PortalColors;
  defaultLanguage: string;
  favIcoUrl: string;
  code: number;
  legacyUrl: string;
  symplicityUrl: string;
  hasLandingPage: boolean;
  hasIesIntegration: boolean;
  hasAdvisorRole: boolean;
  url: string;
  socialMedia: SocialMedia;
  type: PortalType;
  region: string;
  currency: string;
  digitalSignatureOnInternship:boolean
}
