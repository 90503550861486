import { Component, OnInit, Input, HostBinding, HostListener, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {

  @HostBinding('style.border-bottom') public borderSize: string;
  @Input() headerTitle: string | TemplateRef<any> = '';
  @Input() returnUrl: string;
  @Input() queryParams: any = null;
  @Input() border: boolean = true;
  @Input() backButtonTpl: TemplateRef<any> = null;



  isMobile = false;
  ngOnInit(): void {
    if (this.border) {
      this.borderSize = '2px solid #dbe3ea';
    } else {
      this.borderSize = '0';
    }
    this.resolveResize({ target: window });
  }


  @HostListener('window:resize', ['$event'])
  resolveResize(event) {
    this.isMobile = event.target.innerWidth <= 992
  }

  isHeaderTitleString() {
    return typeof this.headerTitle === 'string'
  }


}
